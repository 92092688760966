import React from 'react';
import { Layout } from '../../pos/layout';
import { Link } from 'gatsby';
import { Flex, Text, Box } from 'rebass';
import { Location } from "@reach/router"
import queryString from 'query-string'
import styled from 'styled-components';


export const NavLink = styled(Link)`
height: 30px;
font-family: 'PF BeauSans Pro', -apple-system, sans-serif;
font-size: 17px;
text-decoration:none;
padding-left: 60px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 2.14;
letter-spacing: normal;
color:  ${props => props.theme.colors.blues.peacock};
:hover {
  color: ${props => props.theme.colors.blues.peacock};
}
`;

const SubTitle = styled(Text)`
  height: 12.5px;
  font-family: 'PF BeauSans Pro', -apple-system, sans-serif;
  font-size: 30px;
  font-weight: bold;
  padding-left: 30px;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.83;
  letter-spacing: normal;
  color: ${props => props.theme.colors.blues.peacock};
`

const TitleH1 = styled(Text)`
  font-family: 'PF BeauSans Pro', -apple-system, sans-serif;
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.44;
  letter-spacing: -1.07px;
  color: ${props => props.theme.colors.blues.twilight};`

  const TitleH2 = styled(Text)`
  font-family: 'PF BeauSans Pro', -apple-system, sans-serif;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${props => props.theme.colors.blues.twilight};`

const SubNavLinks = ({location})=>{

  const query = location.search ? queryString.parse(location.search): {}

  const isActive = ({ location, href })=>{

    const isCrnt = location && location.href && location.href.includes(href);
    if(isCrnt){
      return { className: "pos-navbar-active-link-indicator" }
    }
    return null;
  }
  
  return (
    <>
    <div css={{
      backgroundColor:"#f4d448", 
      height: "109px", width:"78px", position:"absolute", top:0 }}>
        <div
        css={{
          marginLeft: "30px",
          background: "#01549b",
          height: "100%",
          borderRadius: "0px 0px 0px 200px"
        }}
        >

        </div>
      </div>
    <Flex py="22px" style={{ "backgroundColor": "#f4d448"}}>
        <SubTitle>
          TI EINAI TO BOXIT
        </SubTitle>
        <NavLink
            to={`/pos/why_we_boxit?stationId=${query.stationId}`}
            partiallyActive={true}
            getProps={isActive}
          >
            OΔΗΓΟΣ 4 ΒΗΜΑΤΩΝ
          </NavLink>
          <NavLink
            to={`/pos/pickup_your_box?stationId=${query.stationId}`}
            partiallyActive={true}
            getProps={isActive}
          >
            ΕΞΥΠΝΗ ΕΠΙΣΤΟΛΗ ΔΕΜΑΤΩΝ
          </NavLink>
          <NavLink
            to={`/pos/pickup_your_box?stationId=${query.stationId}`}
            partiallyActive={true}
            getProps={isActive}
          >
            ΓΙΑ ΙΔΙΩΤΕΣ
          </NavLink>
        </Flex></>)
}

export const SubNavbar = () => (
  <Location >
    {({ location }) => ( 
        <SubNavLinks location={location} />
    )}
</Location>)

export default function WhyWeBoxitPage() {
  return <Layout>
    <Flex id="ssss" flexDirection="column" css={{ flexGrow: 1}}>
     <SubNavbar />
     <Box css={{ padding: "100px 24px", backgroundColor: "white",flexGrow:1 }}>
       <TitleH1>Για ιδιώτες</TitleH1>
       <Box pt="20px">
          <TitleH2>Απλά, γρήγορα, με ασφάλεια!</TitleH2>
       </Box>
     </Box>
     </Flex>
  </Layout>;
}
